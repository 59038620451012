const getStatus = async (statusId) => {
  const status = await post(`/api/status/one`, { statusId });
  return status;
};

const getLatestStatuses = async (limit) => {
  const statuses = await post(`/api/status/latest`, { limit });
  return statuses;
};

const getMostViewedStatuses = async (limit) => {
  const statuses = await post(`/api/status/most-viewed`, { limit });
  return statuses;
};

async function post(url, data) {
  const result = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());

  return result;
}

export { getStatus, getLatestStatuses, getMostViewedStatuses };
