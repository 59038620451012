import '../styles/Loading.css';
const Loading = () => {
  return (
    <>
      <div id='loading-div'>
        <img
          id='loading-gif'
          src='/loading.gif'
          alt='loading.gif'
          draggable='false'
        ></img>
        <h3>Loading Tweet Memory...</h3>
      </div>
    </>
  );
};

export default Loading;
