import '../styles/Button.css';
const Button = ({ text, bgColor: backgroundColor, color, onClick }) => {
  return (
    <>
      <span className='button-span'>
        <button
          className='button'
          // style={{ color, backgroundColor }}
          onClick={onClick}
        >
          {text}
        </button>
      </span>
    </>
  );
};

export default Button;
