import '../styles/Appbar.css';

const Appbar = ({ title }) => {
  return (
    <>
      <div id='appbar-div'>
        <span id='title'>{title}</span>
      </div>
    </>
  );
};

export default Appbar;
