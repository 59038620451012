import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import TweetListing from './pages/TweetListing';
import Status from './pages/Status';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <TweetListing page='home' />
        </Route>
        <Route path='/most-viewed'>
          <TweetListing page='most-viewed' />
        </Route>
        <Route path='/status/*/*'>
          <Redirect to='/' />
        </Route>
        <Route path='/status' component={Status} />
        <Route path='/*'>
          <Redirect to='/' />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
