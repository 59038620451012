import { useEffect, useState } from 'react';

import Appbar from '../components/Appbar';
import Navbar from '../components/Navbar';
import Tweet from '../components/Tweet';
import Loading from '../components/Loading';

import { getLatestStatuses, getMostViewedStatuses } from '../functions/api';

import '../styles/TweetListing.css';

const TweetListing = ({ page }) => {
  const [statusArray, setStatusArray] = useState([]);
  const [title, setTitle] = useState('');

  useEffect(() => {
    setStatusArray(() => []);

    (async () => {
      let statuses;

      switch (page) {
        case 'home':
          setTitle('Home');
					window.document.title = 'Home | Tweet Memory'
          statuses = await getLatestStatuses(30);
          break;
        case 'most-viewed':
          setTitle('Most Viewed');
					window.document.title = 'Most Viewed | Tweet Memory'
          statuses = await getMostViewedStatuses(30);
          break;
        default:
          return;
      }

      setStatusArray(() => [...statuses]);
    })();
  }, [page]);

  return (
    <>
      <Appbar title={title} />
      <Navbar />
      {!statusArray?.length ? (
        <Loading />
      ) : (
        <div id='home-page'>
          <div id='center'>
            {statusArray.map((status) => (
              <Tweet tweet={status} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default TweetListing;
