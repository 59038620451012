import { Link } from 'react-router-dom';

import '../styles/Navbar.css';

const Navbar = () => {
  return (
    <>
      <div id='navbar-div'>
        <div id='navbar'>
          <Link to='/' className='inner-nav'>
            <div id='nav-top'>Home</div>
          </Link>
          <Link to='/most-viewed' className='inner-nav'>
            <div id='nav-top'>Most Viewed</div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Navbar;
