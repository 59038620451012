import { Link } from 'react-router-dom';

import '../styles/Tweet.css';

const Tweet = ({ tweet }) => {
  const statusPath = `/status/${tweet.statusId}`;
  return (
    <>
      <div className='tweet-div'>
        <Link className='link' to={statusPath}>
          <div className='top-div highlight-div'>
            <span className='screen-name'>@{tweet.screenName}</span>
            &nbsp; &nbsp;
            <span className='name'>{tweet.name}</span>
          </div>

          <div className='text padding-top'>{tweet.text}</div>
          <div className='created-at padding-top'>{tweet.createdAt}</div>

          <div className='padding-top'>
            <b className='highlight-div'>Added by</b>
            <div className='mentioner'>@{tweet.mentioner.screenName}</div>
            <div className='added-at'>{tweet.mentioner.addedAt}</div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default Tweet;
