import { useState, useEffect } from 'react';

import Appbar from '../components/Appbar';
import Navbar from '../components/Navbar';
import Loading from '../components/Loading';
import Button from '../components/Button';

import { getStatus } from '../functions/api';

import '../styles/Status.css';

const Status = () => {
  // const object = {
  //   userId: '',
  //   screenName: '',
  //   name: '',
  //   statusId: '',
  //   snapPath: '',
  //   text: '',
  //   createdAt: '',
  //   statusUrl: '',
  //   mentioner: {
  //     userId: '',
  //     screenName: '',
  //     name: '',
  //     statusId: '',
  //     text: '',
  //     addedAt: '',
  //     statusUrl: '',
  //   },
  // };

  const [status, setStatus] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const bucketUrl = 'https://storage.googleapis.com/sejarah-bot/';

  useEffect(() => {
    window.document.title = 'Status | Tweet Memory';
    const statusId = window.location.pathname.split('/')[2];

    (async function fetchStatus() {
      const status = await getStatus(statusId);

      // Status not exists
      if (!status) {
        window.location.href = '/';
        return;
      }

      setStatus(status);
      setImageUrl(bucketUrl + status.snapPath);
    })();
  }, []);

  return (
    <>
      <Appbar title='Status' />
      <Navbar />
      {!status ? (
        <Loading />
      ) : (
        <div id='status-page'>
          <img id='snap' src={imageUrl} alt='status-snapshot' />

          <div id='details-div'>
            <div id='status-details' className='detail-div-element'>
              <div className='title'>Status Details</div>
              <p className='user-id'>
                <b>User Id:</b> {status.userId}
              </p>
              <p className='screen-name'>
                <b>Screen Name:</b> @{status.screenName}
              </p>
              <p className='name'>
                <b>Name:</b> {status.name}
              </p>
              <p id='text'>
                <b>Text:</b> {status.text}
              </p>
              <p id='created-at'>
                <b>Created At:</b> {status.createdAt}
              </p>
              <Button
                text='Original Post'
                bgColor='green'
                color='white'
                onClick={() =>
                  window.open(`https://twitter.com/i/status/${status.statusId}`)
                }
              />
              &nbsp;
              <Button
                text='User Profile'
                bgColor='green'
                color='white'
                onClick={() =>
                  window.open(`https://twitter.com/i/user/${status.userId}`)
                }
              />
            </div>

            <div id='mentioner-details' className='detail-div-element'>
              <div className='title'>Added By</div>
              <p className='user-id'>
                <b>User Id:</b> {status.mentioner.userId}
              </p>
              <p className='screen-name'>
                <b>Screen Name:</b> @{status.mentioner.screenName}
              </p>
              <p className='name'>
                <b>Name:</b> {status.mentioner.name}
              </p>
              <p className='added-at'>
                <b>Added At:</b> {status.mentioner.addedAt}
              </p>
              <Button
                text='User Profile'
                bgColor='green'
                color='white'
                onClick={() =>
                  window.open(
                    `https://twitter.com/i/user/${status.mentioner.userId}`
                  )
                }
              />
            </div>

            <div id='view-details' className='detail-div-element'>
              <p className='added-at'>
                <b>Total Page Views:</b> {status.analytic.view}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Status;
